<template>
    <div>
        <CRow>
            <CCol md="12">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol md="12">
                                AddModule
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CForm v-on:submit.prevent="storeProcess" class="fwdwd">
                        <CCardBody>
                            <div class="form-group">
                                <label for=""><b>Nama Module <span class="text-danger">*</span></b></label>
                                
                                <input type="text" class="form-control" placeholder="Nama Module" v-model="m_nama">
                            </div>
                            <div class="form-group">
                                <label for=""><b>Ditjen Module <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" placeholder="Ditjen Module" v-model="m_ditjen">
                            </div>
                            <div class="form-group">
                                <label for=""><b>Kategori Module<span class="text-danger">*</span></b></label>
                                <v-select :options="list_kategori" v-model="m_kategori"></v-select>
                            </div>
                            <div class="form-group">
                                <label for=""><b>Sebagai Modul<span class="text-danger">*</span></b></label>
                                <v-select :options="list_is_pemasukan" label="labels" v-model="is_pemasukan"></v-select>
                            </div>
                        </CCardBody>
                        <CCardFooter>
                            <div class="text-right">
                                <button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
                            </div>
                        </CCardFooter>
                    </CForm>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
    const null_array = [];
    export default {
        name: "AddJenisTanaman",
        components: {
        },
        data() {
            return {
                list_kategori: null_array,
                list_is_pemasukan: [
                	{
                		"value": 1,
                		"labels": 'Pemasukan',
                	},
                	{
                		"value": 0,
                		"labels": 'Pengeluaran',
                	},
                ],
                m_nama: '',
				m_ditjen: '',
				m_kategori: '',
				is_pemasukan: ''
            }
        },
        methods: {
            storeProcess: function () {
                let self = this;
                Swal.fire({
                    title: '<i class="fa fa-refresh fa-spin"></i>',
                    text: "Loading...",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
                var FormData = require('form-data');
                var data = new FormData();
                data.append('m_nama',this.m_nama);
                data.append('m_ditjen',this.m_ditjen);
                data.append('m_kategori',this.m_kategori);
                data.append('is_pemasukan',this.is_pemasukan.value);
                var config = {
                  method: 'post',
                  url: this.apiLink+'api/crud/module',
                  headers: { 
                    'Authorization': 'Bearer '+this.access_token, 
                  },
                  data : data
                };
                axios(config)
                .then(function (response) {
                    var res_data = response.data;
                    if (res_data.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_user.data.message
                        });
                    }
                    else{
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Anda akan diarahkan ke halaman master module",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getContent();
                                    if (content) {
                                        const b = content.querySelector("b");
                                        if (b) {
                                            b.textContent = Swal.getTimerLeft();
                                        }
                                    }
                                }, 100);
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                self.$router.push({name: 'AllNegara'});
                            }
                        }).catch((error) => {
                            var error_res = error.response.data.meta;
                            if (error_res.code != 200) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: error_res.message
                                });
                            }
                        });
                    }
                });
            },
        },
        created() {
            axios
                .get(this.apiLink + "api/master/category", {
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_join_data_master = response.data;
                    // console.log();
                    if (res_join_data_master.meta.code != "200") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res_join_data_master.data.message,
                        });
                    } else {
                        this.list_kategori = res_join_data_master.data.master;
                    }
                });
        }
    };
</script>